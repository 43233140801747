import styles from './BrandLinks.module.scss'
import { Col, Row } from 'react-bootstrap'
import { useTranslation, withTranslation } from 'next-i18next'
import ThemeImage from '@/components/elements/media/ThemeImage/ThemeImage'

const brands = [
  {
    name: 'Surprise Reisen',
    url: 'https://www.surprise-reisen.ch',
    logo: {
      src: '/images/logos/logo-surprise-reisen.png',
      // width: 217,
      height: 48,
      style: { maxHeight: '48px !important', marginBottom: '-11px' },
    },
  },
  {
    name: 'Kultour Ferienreisen',
    url: 'https://www.kultour.ch',
    logo: {
      src: '/images/logos/logo-kultour-white.png',
      // width: 413,
      height: 106,
    },
  },
  {
    name: 'Carreisen Thurgau',
    url: 'https://www.carreisen-thurgau.ch',
    logo: {
      src: '/images/logos/logo-carreisen-thurgau-white.png',
      // width: 384,
      height: 94,
    },
  },
  {
    name: 'R|EX - Reisen mit Experten',
    url: 'https://rex.tours',
    logo: {
      src: 'images/logos/logo-rex.png',
      // width: 361,
      height: 184,
    },
  },
]

const BrandLinks = () => {
  const { t } = useTranslation('theme')

  return (
    <Row className={styles.root}>
      <Col md={12} lg={{ span: 3, offset: 1 }} className={'mb-3'}>
        <small>{t('partOfSurpriseKultourAG')}:</small>
      </Col>
      {brands.map((brand, index: number) => {
        return (
          <Col xs={6} md={3} lg={2} className={`${styles.item} align-self-center`} key={index}>
            <a target={'_blank'} href={brand.url} rel="noreferrer" title={brand.name}>
              <ThemeImage
                src={brand.logo.src}
                height={brand.logo.height}
                alt={brand.name}
                theme={'kultour'}
                style={brand.logo.style ? brand.logo.style : undefined}
              />
            </a>
          </Col>
        )
      })}
    </Row>
  )
}

export default withTranslation(['common', 'theme'])(BrandLinks)
